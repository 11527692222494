<template>
  <div class="rb-page about-page">
    <div class="container">
      <div
        class="white-box"
        :class="{unselectable: isGuest}"
        @contextmenu="interact"
        @copy="interact"
        @cut="interact"
      >
        <div class="error-wrap">
          <div class="text">
            <h1>{{ $t('notFound.err') }}</h1>
            <p class="text__title">
              {{ $t('notFound.title') }}
            </p>
            <p>{{ $t('notFound.tip') }}</p>
            <router-link
              tag="button"
              :to="link"
              class="btn btn-red"
            >
              {{ $t('notFound.link') }}
            </router-link>
          </div>
          <div class="text-error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    /**
       * получить ссылку для кнопки "на сайт"
       * @return {object}
       */
    link() {
      if (window.localStorage['access-token']) {
        return {
          name: 'profile',
          params: {
            id: 0,
          },
        };
      }
      return {
        name: 'index',
      };
    },
  },
  mounted() {
    document.title = this.$t('notFound.title');
  },
};
</script>

<style scoped="scoped" lang="scss">
  @import "../../assets/scss/vars";

  .rb-page {
    background: $light-grey;

  }

  .text{
    text-align: center;
    color: $dark;
    padding-bottom: 50px;
    h1{
      font-size: 120px;
    }

    &__title{
      font-size: 20px;
    }
    .btn{
      margin-top: 15px;
    }
  }

</style>
